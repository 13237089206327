module.exports = {
  title: "42",
  description: "42, the answer to life the universe and everything",
  author: "jaewokim",
  siteUrl: "https://42.jaewon.dev/",
  links: {
    github: "https://github.com/sanvit",
    // linkedIn: "https://linkedin.com",
    // facebook: "https://www.facebook.com/profile.php?id=100057724153835",
    instagram: "https://www.instagram.com/jaewon.99/",
    etc: "https://profile.intra.42.fr/users/jaewokim",
  },
  utterances: {
    repo: "sanvit/42blog-comments",
    type: "pathname",
  },
}
